<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'Whatsapp'" :subtitle="'Números'" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12 info-header">
          <h4>Números cadastrados</h4>
          <p>
            Tenha todas as informações de seus números cadastrados na plataforma Meta Gerenciador do WhatsAPP. Cadastre
            um novo número de forma fácil, rápida e conveniente.
          </p>
        </div>
        <div class="col-lg-12 col-xl-12">
          <div class="row">
            <div class="col-sm-12">
              <div class="info-alert">
                <div>
                  <span class="material-symbols-outlined info-icon">info</span>
                </div>
                <div>
                  <strong>Solicitação de número:</strong> A solicitação para cadastro de novos números esta sujeita  a um prazo de 7 dias úteis para efetivação. É possível verificar o status quando quiser acessando Ativos de negócios nas Configurações do Meta Business Suite e selecionando essa conta do WhatsApp.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xl-12">
          <div class="row">
            <!-- Content col -->
            <div class="col-12 left-content-column">
              <div class="form-group mb-4">
                <label for="wpp-name">Nome de exibição do perfil do WhatsApp Business*</label>
                <input id="wpp-name" class="form-control" :placeholder="'Adicionar o nome'" maxlength="512">
              </div>
              <!-- Body -->
              <div class="form-group mb-4">
                <label>Descrição da empresa: </label>
                <textarea class="form-control" placeholder="Crie uma breve descrição de sua empresa" rows="5"></textarea>
                <small class="form-text text-muted">0/1024</small>
              </div>
              <!--/Body -->
              <!-- Footer -->
              <div class="form-group mb-4">
                <label for="wpp-footer">Categoria</label>
                <select id="wpp-footer" class="form-control" ></select>
              </div>
              <div class="form-group mb-4">
                <label for="wpp-footer">Telefone</label>
                <tel-input ref="phone_number" v-model="form.phone_number" :value="form.phone_number"/>
              </div>
              <div class="form-group mb-4">
                <hr>
              </div>
              <!--/Footer -->
              <div class="btn-wrapper">
                <div>
                  <div class="link-help">
                    <span class="material-symbols-outlined info-icon">help</span>
                    <a href="https://developers.facebook.com/docs/whatsapp/cloud-api/get-started/migrate-existing-whatsapp-number-to-a-business-account">Suporte</a>
                  </div>
                </div>
                <div class="text-right">
                  <button class="btn btn-outline-primary" @click="$router.go(-1)" style="margin-right: 10px;">{{ $t('app.cancel') }}</button>
                  <button class="btn btn-yup-purple ml-auto">
                    Salvar
                  </button>
                </div>
              </div>
              <div class="form-group mb-4">
                <br><br>
              </div>
            </div>
            <!--/Content col -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Tab from '@/directives/Tab';
import WhatsService from '@/services/whatsapp.service';
import TelInput from '@/components/form/TelInput.vue';

export default {
  name: 'WhatsappDashboard',
  components: {
    PageHeader,
    TelInput,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/whatsapp/reports/history',
        2: '/whatsapp/reports/history',
      },
      optionCalendar: {
        autoApply: true,
        startDate: moment().format('YYYY-MM-DD'),
      },
      numbers: [],
      loading: false,
      filter: {
        status: [],
        from: '',
        to: '',
        campaign: '',
        page: 1,
      },
      campaigns: [],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      form: {
        phone_number: '61996426188',
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
        filters: {
          status: [],
          from: '',
          to: '',
          campaign: '',
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
          page: 1,
        },
      },
    };
  },
  created() {
    this.getCampaign();
  },
  // Pai
  methods: {
    showFilter() {
      this.$refs['filter-modal'].toggle();
    },
    reload() {
      this.$refs.view.fetch(1);
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$nextTick(() => {
        this.reload();
      });
    },
    getCampaign() {
      WhatsService.getNewCampaigns({}).then(
        (response) => {
          this.campaigns = response;
          console.log('FETCH Campanha', response);
        },
        (error) => {
          this.$toast.show({
            title: 'Erro',
            content: 'Erro interno, por favor tente novamente mais tarde.',
            type: 'danger',
          });
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    cleanFilter() {
      this.filter = {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign: '',
        page: 1,
      };
      this.form.filter = {};
      this.reload();
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.form.filters.gte = date[0];
        this.form.filters.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover~.calendar_icon,
.calendar_form:focus-within~.calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}

.card {
  box-shadow: none !important;
}

.nav-tabs {
  border-bottom: 4px solid #6B23DC3D;
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  border-bottom: solid 4px var(--clr-yup-purple);
}

.nav-tabs .nav-item {
  margin-bottom: -4px;
}

::v-deep .icon-calendar {
  color: var(--gray-font-color) !important;
}

.btn-add span {
  color: #6B23DC;
  font-size: 16px !important;
}

table {
  width: 100%;
  border-spacing: 0 12px;
  border-collapse: separate;
  margin: -24px 0;
  table-layout: fixed;

  thead {
    th {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      // border-bottom: color-mix(in srgb, var(--clr-yup-purple) 20%, transparent) 4px solid;
      border-bottom: none;
    }
  }

  tbody {
    tr {

      td {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid #E5E5E5;
        padding: 16px;

        .material-symbols-outlined {
          vertical-align: text-bottom;
        }
      }

      td:first-child {
        border-left: 1px solid #E5E5E5;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-right: 1px solid #E5E5E5;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
}

.icon-red {
  color: #AD1519;
}

.info-header h4 {
  font-size: 20px;
  color: #101828;
  font-weight: 400;
}

.info-header p {
  color: #595959;
  font-weight: 400;
}
.info-alert {
  background: #F1F7FD;
  font-size: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
  flex-direction: row;
  display: flex;
}
.info-alert .info-icon {
  color: #0070E4;
  margin-right: 10px;
  margin-top: 5px;
}
.link-help {
  display: flex;
  flex-direction: row;
}
.link-help span{
  font-size: 16px;
  margin-top: 2px
}
.link-help a {
  font-size: 14px;
  margin-left: 5px;
  color: #595959;
  text-decoration: underline;
}
.btn-wrapper {
  display: flex;
  flex-direction: row;
}
.btn-wrapper div {
  width: 50%;
}
</style>
